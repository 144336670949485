.tcStyle {
    font-family: 'Bookman Old Style';
    color: black;
    font-size: fs-6;
}

.tcFontSize {
    font-size: fs-4;
}

#tdForReceiptNoBorder td {

    border: transparent;
}

@page {
    size: A3;
    /* // margin-top: 10mm; */
    margin-left: 10mm;
    /* // margin-bottom: 10mm; */
    /* // margin-right: 10mm; */
}