/* General styles for the print container */
.print-container {
  width: 100%;
  height: 100%;
  /*overflow: hidden; /* Hide overflow to remove scrollbars */
  height: initial !important;
    overflow: initial !important;
}

/* Print-specific styles */
@media print {
  /* Hide scrollbar for all elements */
  * {
    -ms-overflow-style: initial;  /* IE and Edge */
    scrollbar-width: initial;  /* Firefox */
    height: initial !important;
    overflow: initial !important;
  }

  *::-webkit-scrollbar {
    display: initial;  /* Chrome, Safari, Opera */
  }

  body {
    margin: 0;
    padding: 0;
  }

  /* Style the print content to fit the page */
  .print-container {
    width: 100%;
    height: 100%;
  }

  .page {
    page-break-after: always;
  }

  /* Adjust page size and margins */
  @page {
    size: A4;
    margin: 10mm;
  }
}

@media screen {
  .print-only {
      display: none;
  }
}
@media print {
  .print-only {
      display: block;
  }
}

/* @media all {
  .page-break {
    display: none;
  }
} */

/*@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    /* //-webkit-print-color-adjust: exact; 
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}*/