/* #tdForReceipt td {
    border: solid black 1px;
}

#thForReceipt th {
    border: solid black 1px;
}

#tdForReceiptNoBorder td {
    
    border: transparent;
} */

/* @page {
    size: A4 landscape;
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-top: 0.5in;
    margin-bottom:  0.5in;
} */

/* @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
} */

.blinking-button {
    /* background-color:palevioletred; */
    color: white;
     animation: blink 1s infinite; 
  }

  .blinking-buttonFeePayment {
    /* background-color:palevioletred; */
    color: white;
     animation: blink 1s infinite; 
  }

@keyframes clickForFeePayment {
    0% { background-color:lightblue; }
    100% { background-color:blue;} 

    /* 0% {
        transform:scale3d(1,1,1);
      }
      100% {
        transform:scale3d(1.1,1.3,1.1);
      } */

}

@keyframes payFees {
    0% { background-color:lightgreen; } 
    100% { background-color: green; } 

    /* 0% {
        transform:scale3d(1,1,1);
      }
      100% {
        transform:scale3d(1.1,1.3,1.1);
      } */
}

/* .ribbon-1 {
    position: relative;
    background: #08769b;
    box-shadow: 0 0 0 999px #08769b;
    clip-path: inset(0 0 0 0);
  }
  .left {
    inset: 0 auto auto 0;
    transform-origin: 100% 0;
    transform: translate(-29.3%) rotate(-45deg);
  }
  .right {
    inset: 0 0 auto auto;
    transform-origin: 0 0;
    transform: translate(29.3%) rotate(45deg);
  } */

  .ribbon-2 {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 10px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
     padding: 0 10px var(--f) calc(10px + var(--r)); 
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    box-shadow: 0 calc(-1*var(--f)) 0 inset;
  }

  
  @media (max-width: 767px) {
    .media-breakpoint-down {
      position: fixed;
      z-index: 1020;
      top: 10px;
      width: 100%;
      margin-bottom: 50px;
      
    }
    .left {
      width: fit-content;
      position: relative;
      padding:auto;  
    }
  
  .right {
      position: relative;
      width: fit-content;
      margin-left: auto;
      padding-bottom: 30px;
    }
  
  .subheader-separator {
      display: none;
    }
    .pageDown{
      margin-top: 70px;
    }
  }
  
  
  


